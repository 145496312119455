import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts/Layout'
import LayoutArchive from '../layouts/LayoutArchive'


//Component
const CasesTemplate = ( props ) => {

  const data = props.data

  const current_meta = {
    meta_title:     data.wordpressAcfOptions.options.case_meta_title,
    meta_desc:      data.wordpressAcfOptions.options.case_meta_desc,
  };

  const category_items = data.allWordpressWpCaseCategory.edges
  const archive_items = data.allWordpressWpCase.edges

  return (
    <div>
      <Layout 
        current_meta = {current_meta} 
      >

        <LayoutArchive 
          category_items = {category_items}
          archive_items = {archive_items}
        />

      </Layout>
    </div>
  )
}


//Export
export default CasesTemplate


//Query
export const caseQuery = graphql`
query caseQuery{
  wordpressAcfOptions {
    options {
      case_meta_title
      case_meta_desc
    }
  }
  allWordpressWpCaseCategory (sort: {fields: description, order: ASC }) {
    edges {
      node {
        name
        slug
        link
      }
    }
  }
  allWordpressWpCase (sort: {fields: menu_order, order: ASC}) {
    edges {
      node {
        id
        title
        link
        acf {
          label
          main_image {
            alt_text
            localFile {
              childImageSharp {
                fixed(width: 600, height: 400, cropFocus: CENTER) {
                  ...Fixed
                }
              }
            }
          }
        }
        cpt_categories {
          name
          slug
        }
      }
    }
  }
}
`